import React, { lazy, Suspense } from 'react';
import { RouterProvider, createBrowserRouter } from "react-router-dom";
import Body from './Body';
const Form = lazy(() => import('./components/Form/Form'));
const FormHindi = lazy(() => import('./components/FormHindi/FormHindi'));
const ConfirmationPage = lazy(() => import('./components/Form/ConfirmationPage'));

const appRouter = createBrowserRouter([
  {
    path: "/",
    element: <Body />,
    children: [
      {
        path: "/",
        element: <Suspense fallback={<></>}><Form /></Suspense>,
      },
      {
        path: "/hi",
        element: <Suspense fallback={<></>}><FormHindi /></Suspense>,
      },
      {
        path: "/form/sucess",
        element: <Suspense fallback={<></>}><ConfirmationPage /></Suspense>,
      }
    ],
  },
]);

const App = () => {

  return (
    <div className="w-screen">
      <RouterProvider router={appRouter} />
    </div>
  );
}

export default App;